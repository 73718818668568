import { BrightnessInfo, BrightnessInfoMask, ModelType, ModelTypeName, TimeStruct } from "./utils";
import { Device, PredictionFormulaResult, TinyBbox } from "./prediction";
import { ImageExtensionFormat, Picture, StringFilterType } from "./image";

import { AuditLogChange } from "./audit";
import { ExtraInfo } from "../plugin/models";
import { Organisation } from "./organisation";

// The following types (ImageStatistics, LaplacianResult, SessionContent) were copied from ai-client-module package that now it's not available in Nexus.
export interface ImageStatistics {
  mean: number;
  variance: number;
}
export interface LaplacianResult extends ImageStatistics {
  duration: number;
}
export interface SessionContent {
  id: string;
  prediction: PredictionFormulaResult;
  laplacian?: LaplacianResult;
}

export interface CroppingImgValidationResult {
  brightness?: number;
}

export type CroppingImgValidationResults = Record<CroppingKey, CroppingImgValidationResult>;

export type ValidationKey =
  | keyof CroppingImgValidationResult
  | "level_of_assurance"
  | "img_validation_level"
  | "metadata_device";

export type ValidationResults = Record<ValidationKey, unknown>;

export interface Log {
  id: number;
  time: number;
  fromCamera: number;
  expectedResult: string;
  expectedResultChanges: AuditLogChange[];
  tag: string;
  userId: number;
  userEmail?: string;
  modelPredictionList: Record<ModelType, ModelPredictionType>;
  os: string;
  browser: string;
  deviceType: string;
  chOS: string;
  chOSVersion: string;
  chBrowser: string;
  chIsMobile: boolean;
  chDeviceMemory: number;
  chSaveData: boolean;
  chDownlink: number;
  chECT: string;
  chRTT: number;
  chArch: string;
  chBitness: number;
  chModel: string;
  feedback: string;
  feedbackChanges: AuditLogChange[];
  modelFormula: ModelType[];
  predictionResult: PredictionFormulaResult;
  scanType: string;
  snifferEndpoint: string;
  snifferBaseUrl: string;
  snifferMode: string;
  expectedLocation: string;
  expectedLocationChanges: AuditLogChange[];
  expectedBrightness: string;
  expectedBrightnessChanges: AuditLogChange[];
  storeImagesEndpoint?: boolean;
  isSessionResult?: boolean;
  sessionId?: string;
  sessionResult?: string;
  sessionScore?: number;
  sessionFormula?: string;
  organisationName?: string;
  organisationToken?: string;
  thumbnail: string;
  predictionStatusCode?: number;
  error?: string;
  errorCode?: string;
  laplacianMean?: number;
  laplacianVariance?: number;
  laplacianTime?: number;
  brightnessScore?: BrightnessInfo["score"];
  brightnessDuration?: BrightnessInfo["duration"];
  brightnessScoreMask?: BrightnessInfoMask["score"];
  brightnessLowerMask?: BrightnessInfoMask["lowerMask"];
  brightnessUpperMask?: BrightnessInfoMask["upperMask"];
  brightnessDurationMask?: BrightnessInfoMask["duration"];
  brightnessTooDarkThreshold?: BrightnessInfoMask["tooDarkThreshold"];
  brightnessTooBrightThreshold?: BrightnessInfoMask["tooBrightThreshold"];
  brightnessLowerMaskThreshold?: BrightnessInfoMask["lowerMaskThreshold"];
  brightnessUpperMaskThreshold?: BrightnessInfoMask["upperMaskThreshold"];
  brightnessLowerMaskAcceptanceThreshold?: BrightnessInfoMask["lowerMaskAcceptanceThreshold"];
  brightnessUpperMaskAcceptanceThreshold?: BrightnessInfoMask["upperMaskAcceptanceThreshold"];
  croppingImgValidationResults?: CroppingImgValidationResults;
  validationResults?: ValidationResults;
  metadataDeviceField?: string;
  secureVersion?: string;
  levelOfAssurance?: string;
  operator?: string;
  threshold?: number;
  isSecure?: boolean;
  imgValidationLevel?: string;
}
export type ModelPredictionType = LogPrediction | AgePrediction | BiologicalSexPrediction;

export enum PredictionSource {
  Upload = 0,
  Camera,
  ExternalClient,
  DataCollection,
}

export interface LogPrediction {
  prediction: PredictionFormulaResult;
  real: number;
  error?: string;
  data?: AntiSpoofingData;
}

// Ignore interface shouldn't starts with 'I' lint error as this entity starts
// with 'I' -> IDRnDPrediction.
// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IDRnDPrediction {
  prediction: PredictionFormulaResult;
  real: number;
  error?: string;
  quality: number;
}

export interface AgePrediction {
  age: number;
  stDev: number;
  error?: string;
}

export interface BiologicalSexPrediction {
  probs: number;
  error?: string;
}

export interface PaginationLog {
  logs: Log[];
  count: number;
}

export interface MetadataField {
  device: string;
}

export interface SecureField {
  version: string;
  token: string;
  signature: string;
  verification: string;
}

// Log detail
export interface LogDetail {
  id: number;
  tag: string;
  userId?: number;
  userEmail?: string;
  modelResult: TensorflowResult;
  tinyBBoxOriginal?: TinyBbox;
  tinyFaceDetectorScore: number;
  metadata?: Metadata;
  configuration: ConfigurationInfo;
  tinyBBoxCropped?: TinyBbox;
  error?: string;
  validationResults?: ValidationResults;
  extraInfo?: ExtraInfo;
  ip?: string;
  sessionId?: string;
  applicationId?: string;
  requestId?: string;
  endpointResult?: Record<string, any>;
  organisation?: Organisation;
  croppedImgsValidations?: CroppingImgValidationResults;
  levelOfAssurance?: string;
  operator?: string;
  threshold?: number;
  metadataField?: MetadataField;
  imgValidationLevel?: string;
  secureField?: SecureField;
  isSecure?: boolean;
}

export interface LogData {
  item: Log;
  details: LogDetail;
}

export type ModelResultType = AgeEstimationResult | AntiSpoofingResult | BiologicalSexResult | IDRnDResult;

export interface TensorflowResult {
  mtcnn: MTCNNResult;
  cropping: CroppingResult;
  aiResult: AIResults;
  processingTime: TimeStruct[];
}

export interface AIResults {
  models: Record<ModelType, ModelResultType>;
  processingTime: TimeStruct[];
}

export interface AntiSpoofingResult extends LogPrediction {
  processingTime: TimeStruct[];
  checkpoint: string;
  classificationThreshold: number;
  debugImage: string;
}

type AntiSpoofingData = undefined | FIQAData | RGBFASData;

export interface FIQAData {
  probs: number[];
}

export interface RGBFASData {
  embeddingNorm?: number;
  confidence?: number;
  confidenceThreshold?: number;
}

export interface AgeEstimationResult {
  age: number;
  stDev: number;
  ageDistribution: number[];
  processingTime: TimeStruct[];
  checkpoint: string;
  error?: string;
}

export interface BiologicalSexResult {
  probs: number;
  checkpoint: string;
  processingTime: TimeStruct[];
  error?: string;
}

// Ignore interface shouldn't starts with 'I' lint error as this entity starts
// with 'I' -> IDRnDResult.
// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IDRnDResult extends LogPrediction {
  quality: number;
  qualityThreshold: number;
  score: number;
  threshold: number;
  checkpoint: string;
  processingTime: TimeStruct[];
}

export interface MTCNNResult {
  bbox: number[][];
  landmarks: number[];
  processingTime: TimeStruct[];
}

export type CroppingKey =
  | "192x256"
  | "112x112"
  | "256x256"
  | "256x256_linear_warpfill"
  | "256x256margin12_linear_warpfill"
  | "224x224"
  | "224x224_linear_warpfill"
  | "112x112margin20"
  | "224x224offset"
  | "112x112margin24"
  | "112x112margin40_linear_warpfill"
  | "original_img";

export interface CroppingResult {
  bbox: number[];
  landmarks: number[];
  imgs: Record<CroppingKey, string>;
  processingTime: TimeStruct[];
}

export interface Metadata {
  ua: string;
  os: string;
  browser: string;
  device: string;
  chOS: string;
  chOSVersion: string;
  chBrowser: string;
  chIsMobile: boolean;
  chDeviceMemory: number;
  chSaveData: boolean;
  chDownlink: number;
  chECT: string;
  chRTT: number;
  chArch: string;
  chBitness: number;
  chModel: string;
  chUA: string;
  serialized: Device;
}

export interface ConfigurationInfo {
  minFaceSize?: number;
  imageExtensionFormat: ImageExtensionFormat;
  imageJPEGCompression: number;
}

export interface ImageMetadata {
  height: number;
  size: number;
  width: number;
}

export interface LogImg {
  id: number;
  img: string;
  croppedImg?: string;
  pictureUsed?: Picture;
}

export enum Feedback {
  Unknown = "unknown",
  Correct = "correct",
  Incorrect = "incorrect",
}

export function FeedbackToString(feedback: Feedback) {
  switch (feedback) {
    case Feedback.Correct:
      return "Correct";
    case Feedback.Incorrect:
      return "Incorrect";
    default:
      return "Unknown";
  }
}

export enum ExpectedResult {
  Unknown = "unknown",
  Real = "real",
  DisplayAttack = "display_attack",
  PrintAttack = "print_attack",
  MaskAttack = "mask_attack",
}

export function expectedResultToString(expectedResult: ExpectedResult) {
  switch (expectedResult) {
    case ExpectedResult.Real:
      return "Real";
    case ExpectedResult.DisplayAttack:
      return "Display Attack";
    case ExpectedResult.PrintAttack:
      return "Print Attack";
    case ExpectedResult.MaskAttack:
      return "Mask Attack";
    default:
      return "Unknown";
  }
}

export interface SessionInfo {
  sessionId: string;
  requestId?: string;
  predictionImages: SessionContent[];
  expectedResult?: ExpectedResult;
  expectedLocation?: ExpectedLocation;
  expectedBrightness?: ExpectedBrightness;
  device: Device;
  prediction?: PredictionFormulaResult;
  score?: number;
  formula?: string;
  imageFormat?: string;
  imageCompression?: number;
}

export enum ExpectedLocation {
  Indoor = "indoor",
  Outdoor = "outdoor",
  Unknown = "unknown",
}

// expectedLocationToFilterValue is a function that receives an expected location
// and if this is unknown it transforms it into a null value in order to make it
// selectable as a filter value, otherwise it returns as it is
export function expectedLocationToFilterValue(expectedLocation: ExpectedLocation) {
  if (expectedLocation === ExpectedLocation.Unknown) {
    return StringFilterType.Null;
  } else {
    return expectedLocation;
  }
}

export enum ExpectedBrightness {
  Dark = "dark",
  Normal = "normal",
  Bright = "bright",
  Unknown = "unknown",
}

// expectedBrightnessToFilterValue is a function that receives an expected brightness
// and if this is unknown it transforms it into a null value in order to make it
// selectable as a filter value, otherwise it returns as it is
export function expectedBrightnessToFilterValue(expectedBrightness: ExpectedBrightness) {
  if (expectedBrightness === ExpectedBrightness.Unknown) {
    return StringFilterType.Null;
  } else {
    return expectedBrightness;
  }
}

export enum LogTableColumn {
  ID = "ID",
  Thumbnail = "Thumbnail",
  Time = "Time",
  PredictionStatus = "Prediction Status",
  ErrorCode = "ErrorCode",
  PredictionResult = "Prediction Result",
  ExpectedResult = "Expected Result",
  ExpectedLocation = "Expected Location",
  ExpectedBrightness = "Expected Brightness",
  Feedback = "Feedback",
  Source = "Source",
  UserEmail = "User Email",
  Organisation = "Organisation",
  ScanType = "Scan Type",
  Session = "Session",
  IsSessionResult = "Is Session Result",
  SessionResult = "Session Result",
  SessionScore = "Session Score",
  SessionFormula = "Session Formula",
  OS = "OS",
  Browser = "Browser",
  Device = "Device",
  ClientHintsOS = "Client Hints OS",
  ClientHintsOSVersion = "Client Hints OS Version",
  ClientHintsBrowser = "Client Hints Browser",
  ClientHintsIsMobile = "Client Hints Is Mobile",
  ClientHintDeviceMemory = "Client Hints Device Memory",
  ClientHintSaveData = "Client Hints Save Data",
  ClientHintDownlink = "Client Hints Downlink",
  ClientHintECT = "Client Hints ECT",
  ClientHintRTT = "Client Hints RTT",
  ClientHintArch = "Client Hints Arch",
  ClientHintBitness = "Client Hints Bitness",
  ClientHintModel = "Client Hints Model",
  Tag = "Tag",
  AIServiceURL = "AI Service URL",
  Endpoint = "Endpoint",
  Mode = "Mode",
  StoreImagesEndpoint = "Store Images Endpoint",
  LaplacianScore = "Laplacian Score",
  LaplacianDuration = "Laplacian Duration",
  BrightnessScore = "Brightness Score",
  BrightnessDuration = "Brightness Duration",
  BrightnessValidationsResults = "Brightness Validation",
  MetadataDeviceField = "Metadata Device Field",
  SecureVersion = "SICAP Client Version",
  LevelOfAssurance = "Level of assurance",
  Operator = "Operator",
  Threshold = "Threshold",
  IsSecure = "Is secure",
  ImgValidationLevel = "Image validation level",
}

export const getAllLogTableColumnDescription = (): Record<LogTableColumn | ModelTypeName, string> => {
  const logTableColumnDescription: Record<string, string> = {
    [LogTableColumn.ID]: "Database identifier",
    [LogTableColumn.Thumbnail]: "Reduced-size image from the picture used as input for the prediction",
    [LogTableColumn.Time]: "Prediction date",
    [LogTableColumn.PredictionStatus]: "HTTP status code from the request to AI Services",
    [LogTableColumn.ErrorCode]: "Error code from the request to AI Services",
    [LogTableColumn.PredictionResult]: "Indicates whether the result of the prediction was real, fake or undetermined",
    [LogTableColumn.ExpectedResult]: "Expected result selected by the user before the prediction",
    [LogTableColumn.ExpectedLocation]: "Place variation selected by the user in the prediction",
    [LogTableColumn.ExpectedBrightness]: "Brightness variation selected by the user in the prediction",
    [LogTableColumn.Feedback]: "Indicates whether the prediction result and the correct result match",
    [LogTableColumn.Source]: "Source of the image used as input for the prediction",
    [LogTableColumn.UserEmail]: "Email address associated to the user that took the prediction",
    [LogTableColumn.Organisation]: "Organisation name whose token was used for the prediction",
    [LogTableColumn.ScanType]: "Kind of scan feature used for the prediction",
    [LogTableColumn.Session]: "Session ID associated to a prediction using the multicapture feature",
    [LogTableColumn.IsSessionResult]:
      "Indicates whether the log is used to obtain the result of the session prediction",
    [LogTableColumn.SessionResult]: "Indicates whether the prediction was real or not",
    [LogTableColumn.SessionScore]: "Indicates whether the whole session prediction is real or not",
    [LogTableColumn.SessionFormula]: "Method used to calculate the Session Score",
    [LogTableColumn.OS]: "Operating system from the device where the prediction was taken",
    [LogTableColumn.Browser]: "Browser from the device where the prediction was taken",
    [LogTableColumn.Device]: "Device from the device where the prediction was taken",
    [LogTableColumn.ClientHintsOS]: "Operating system on client hints from the device where the prediction was taken",
    [LogTableColumn.ClientHintsOSVersion]:
      "Operating system version on client hints from the device where the prediction was taken",
    [LogTableColumn.ClientHintsBrowser]: "Browser on client hints from the device where the prediction was taken",
    [LogTableColumn.ClientHintsIsMobile]:
      "Client hints value that indicates if the device where the prediction was taken is a mobile",
    [LogTableColumn.ClientHintDeviceMemory]: "This client hint indicates the approximately RAM user's device has.",
    [LogTableColumn.ClientHintSaveData]: "It indicates the client's preference for a reduced data usage mode.",
    [LogTableColumn.ClientHintDownlink]: "Is the approximately downstream speed (in MB) the user has.",
    [LogTableColumn.ClientHintECT]: "Depending on Downlink and RTT fields we can find the effective connection type.",
    [LogTableColumn.ClientHintRTT]:
      "It indicated the approximate length of a Round Trip Time including the time server spends processing the request.",
    [LogTableColumn.ClientHintArch]: "The client CPU architecture.",
    [LogTableColumn.ClientHintBitness]: "the 'bitness' of the user-agent's underlying CPU architecture",
    [LogTableColumn.ClientHintModel]: "The device model on which the browser is running.",
    [LogTableColumn.Tag]: "Label used to identify each log entry",
    [LogTableColumn.AIServiceURL]: "Base URL of the AI Services",
    [LogTableColumn.Endpoint]: "AI Services endpoint used for the prediction",
    [LogTableColumn.Mode]: "Mode used for the request to AI Services API",
    [LogTableColumn.StoreImagesEndpoint]: "Indicates whether the image was stored in database in a sniffer prediction",
    [LogTableColumn.LaplacianScore]: "Indicated how blurry is the prediction image",
    [LogTableColumn.LaplacianDuration]: "Time duration for the Laplacian processing",
    [LogTableColumn.BrightnessScore]: "Brightness level of the face area in the image",
    [LogTableColumn.BrightnessDuration]: "Time spent to calculate the brightness level of the image",
    [LogTableColumn.BrightnessValidationsResults]:
      "Brightness validation from the AI service (Single float if only one cropping validation or json object if more than one)",
    [LogTableColumn.MetadataDeviceField]: "Metadata device sent by the client",
    [LogTableColumn.SecureVersion]: "Version of the SICAP client used in the prediction",
    [LogTableColumn.LevelOfAssurance]: "Level of assurance sent by the client",
    [LogTableColumn.Operator]: "Operator sent by the client",
    [LogTableColumn.Threshold]: "Threshold sent by the client",
    [LogTableColumn.IsSecure]: "If FCM secure version was used in the request by the client",
    [LogTableColumn.ImgValidationLevel]: "Image validation level sent by the client",
  };

  Object.values(ModelTypeName).forEach((mt) => (logTableColumnDescription[mt] = `Prediction result for ${mt} model`));

  return logTableColumnDescription;
};
