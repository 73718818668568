import { AccordionSummary, Grid, Typography } from "@material-ui/core";
import { FilterSection, Filters, initialFilters } from "../../../utils/filters";
import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Button } from "../../Utils/Button";
import ClearIcon from "@material-ui/icons/Clear";
import Config from "../../../config";
import { Filter } from "../../../models/filter";
import { FilterChip } from "./FilterChip";
import { FilterContainer } from "./FilterContainer";
import { LogService } from "../../../api/log";
import SearchIcon from "@material-ui/icons/Search";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

const logService = new LogService(Config.apiUrl);

const formatIsSecureFilter = (value: (boolean | null)[]) =>
  value.map((v) => {
    if (v === undefined || v === null) {
      return "unknown";
    }
    return v.toString();
  });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: "20px",
    },
    expansionPanel: {
      boxShadow: "none",
    },
    accordionSummaryContainer: { display: "none" },
    filterButtonsContainer: {
      textAlign: "right",
    },
    filterButtonText: {
      marginRight: "6px",
      color: theme.palette.primary.light,
    },
    filterButtons: { margin: "5px" },
    filterContainer: {
      display: "flex",
      padding: "24px",
      width: "100%",
    },
    tabs: {
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "15px",
      },
    },
  }),
);

interface FilterPanelProps {
  expanded: boolean;
  onSetFilters: (filters: Filter[]) => void;
}

export const FilterPanel: React.FC<FilterPanelProps> = (props) => {
  const classes = useStyles();

  const [filters, setFilters] = useState(initialFilters);
  const [stagedFilters, setStagedFilters] = useState(initialFilters);
  const [tab, setTab] = React.useState<FilterSection>(FilterSection.Request);

  const { expanded, onSetFilters } = props;

  useEffect(() => {
    logService
      .filterValues()
      .then((res) => {
        if (res) {
          const filterAux = JSON.parse(JSON.stringify(initialFilters)) as Filters;
          filterAux.OS.subtitles = res.os;
          filterAux.OS.values = res.os;
          filterAux.browser.subtitles = res.browsers;
          filterAux.browser.values = res.browsers;
          filterAux.device.subtitles = res.devices;
          filterAux.device.values = res.devices;
          filterAux.clientHintsOS.subtitles = res.clientHintsOS;
          filterAux.clientHintsOS.values = res.clientHintsOS;
          filterAux.clientHintsOSVersions.subtitles = res.clientHintsOSVersions;
          filterAux.clientHintsOSVersions.values = res.clientHintsOSVersions;
          filterAux.clientHintsBrowser.subtitles = res.clientHintsBrowsers;
          filterAux.clientHintsBrowser.values = res.clientHintsBrowsers;
          filterAux.clientHintsDeviceMemory.subtitles = res.clientHintsDeviceMemory.map((s) => `${s}`);
          filterAux.clientHintsDeviceMemory.values = res.clientHintsDeviceMemory;
          filterAux.clientHintsDownlink.subtitles = res.clientHintsDownlink.map((s) => `${s}`);
          filterAux.clientHintsDownlink.values = res.clientHintsDownlink;
          filterAux.clientHintsECT.subtitles = res.clientHintsECT;
          filterAux.clientHintsECT.values = res.clientHintsECT;
          filterAux.clientHintsRTT.subtitles = res.clientHintsRTT.map((s) => `${s}`);
          filterAux.clientHintsRTT.values = res.clientHintsRTT;
          filterAux.clientHintsArch.subtitles = res.clientHintsArch;
          filterAux.clientHintsArch.values = res.clientHintsArch;
          filterAux.clientHintsBitness.subtitles = res.clientHintsBitness.map((s) => `${s}`);
          filterAux.clientHintsBitness.values = res.clientHintsBitness;
          filterAux.clientHintsModel.subtitles = res.clientHintsModel;
          filterAux.clientHintsModel.values = res.clientHintsModel;
          filterAux.errorCode.subtitles = res.errorCodes;
          filterAux.errorCode.values = res.errorCodes;
          filterAux.httpStatus.subtitles = res.httpStatus.map((s) => `${s}`);
          filterAux.httpStatus.values = res.httpStatus;
          filterAux.sniffer.subtitles = res.sniffers;
          filterAux.sniffer.values = res.sniffers;
          filterAux.snifferUrl.subtitles = res.snifferUrls;
          filterAux.snifferUrl.values = res.snifferUrls;
          filterAux.snifferMode.subtitles = res.snifferModes;
          filterAux.snifferMode.values = res.snifferModes;
          filterAux.secureVersion.subtitles = res.secureVersions;
          filterAux.secureVersion.values = res.secureVersions;
          filterAux.deepFasVersion.subtitles = res.deepFasVersions;
          filterAux.deepFasVersion.values = res.deepFasVersions;
          filterAux.maskDetectorVersion.subtitles = res.maskDetectorVersions;
          filterAux.maskDetectorVersion.values = res.maskDetectorVersions;
          filterAux.patchFasVersion.subtitles = res.patchFasVersions;
          filterAux.patchFasVersion.values = res.patchFasVersions;
          filterAux.deepFasRgbVersion.subtitles = res.deepFasRgbVersions;
          filterAux.deepFasRgbVersion.values = res.deepFasRgbVersions;
          filterAux.deepFasRgbV2Version.subtitles = res.deepFasRgbV2Versions;
          filterAux.deepFasRgbV2Version.values = res.deepFasRgbV2Versions;
          filterAux.effNetVersion.subtitles = res.effNetVersions;
          filterAux.effNetVersion.values = res.effNetVersions;
          filterAux.metaFASVersion.subtitles = res.metaFASVersions;
          filterAux.metaFASVersion.values = res.metaFASVersions;
          filterAux.metaFASV2Version.subtitles = res.metaFASV2Versions;
          filterAux.metaFASV2Version.values = res.metaFASV2Versions;
          filterAux.metaFASV2BVersion.subtitles = res.metaFASV2BVersions;
          filterAux.metaFASV2BVersion.values = res.metaFASV2BVersions;
          filterAux.ageEstimationVersion.subtitles = res.ageEstimationVersions;
          filterAux.ageEstimationVersion.values = res.ageEstimationVersions;
          filterAux.biologicalSexVersion.subtitles = res.biologicalSexVersions;
          filterAux.biologicalSexVersion.values = res.biologicalSexVersions;
          filterAux.qualityVersion.subtitles = res.qualityVersions;
          filterAux.qualityVersion.values = res.qualityVersions;
          filterAux.contextPatchVersion.subtitles = res.contextPatchVersions;
          filterAux.contextPatchVersion.values = res.contextPatchVersions;
          filterAux.idRnDVersion.subtitles = res.idRnDVersions;
          filterAux.idRnDVersion.values = res.idRnDVersions;
          filterAux.isSecure.subtitles = formatIsSecureFilter(res.isSecure);
          filterAux.isSecure.values = res.isSecure;
          filterAux.metaFASV3Version.subtitles = res.metaFASV3Versions;
          filterAux.metaFASV3Version.values = res.metaFASV3Versions;
          filterAux.metaFASV4Version.subtitles = res.metaFASV4Versions;
          filterAux.metaFASV4Version.values = res.metaFASV4Versions;
          filterAux.metaFASV5Version.subtitles = res.metaFASV5Versions;
          filterAux.metaFASV5Version.values = res.metaFASV5Versions;
          filterAux.metaFASV7Version.subtitles = res.metaFASV7Versions;
          filterAux.metaFASV7Version.values = res.metaFASV7Versions;
          filterAux.levelOfAssurance.subtitles = res.levelOfAssurance;
          filterAux.levelOfAssurance.values = res.levelOfAssurance;
          filterAux.operator.subtitles = res.operator;
          filterAux.operator.values = res.operator;
          filterAux.threshold.subtitles = res.threshold.map((t) => `${t}`);
          filterAux.threshold.values = res.threshold;
          filterAux.imgValidationLevel.subtitles = res.imgValidationLevel;
          filterAux.imgValidationLevel.values = res.imgValidationLevel;
          setFilters(filterAux);
          setStagedFilters(filterAux);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  /**
   * filterRows is a method used to overwrite filters collection and send it to the server.
   * @param  value It is the value that will be used to filter the log list.
   * @param  propertyName It is the attribute by which the log list will be filtered.
   * @param  op It is the operation used to compare a propertyName with a the value in the query (<, >, =).
   * @param  search It indicates the component should propagate the filter outside component.
   * */
  const filterRows = (
    value: string | number | boolean | null | undefined,
    propertyName: keyof Filters,
    op: string | undefined,
    search: boolean,
    secondValue: string | number | null | undefined,
    secondOp: string | undefined,
  ) => {
    //filterlist is a copy of filters
    const filterList = JSON.parse(JSON.stringify(filters)) as Filters;
    //If true the filter value and op are reset. If not the filter value is overwritten with a new one
    filterList[propertyName].value = value;
    filterList[propertyName].op = op;
    filterList[propertyName].secondValue = secondValue;
    filterList[propertyName].secondOp = secondOp;

    //If true, the method only overwrites the filters collection. If not, it sends the collection to the server too
    if (search) {
      const filterListOut = JSON.parse(JSON.stringify(filterList)) as Filters;
      onSetFilters(Object.keys(filterListOut).map((key) => filterListOut[key as keyof Filters]));
    }
    setFilters(filterList);
  };

  const handleChangeTab = (tab: FilterSection) => {
    setTab(tab);
  };

  const handleDeleteChip = (column: string) => {
    const filterList = JSON.parse(JSON.stringify(filters)) as Filters;
    const keys = Object.keys(filters).filter((key) => {
      return filters[key as keyof Filters].column === column;
    });
    const property = keys[0] as keyof Filters;
    filterList[property].value = undefined;
    filterList[property].op = undefined;
    filterList[property].secondValue = undefined;
    filterList[property].secondOp = undefined;
    setFilters(filterList);
  };

  return (
    <div className={classes.root}>
      <Accordion className={classes.expansionPanel} expanded={expanded}>
        <div className={classes.accordionSummaryContainer}>
          <AccordionSummary />
        </div>
        <AccordionDetails>
          <Grid container spacing={6}>
            <Grid item container xs={12} sm={9} spacing={3}>
              {Object.values(filters)
                .filter((f) => f.value !== undefined || f.secondValue !== undefined)
                .map((filter) => {
                  return <FilterChip filter={filter} handleDelete={handleDeleteChip} key={filter.title} />;
                })}
            </Grid>
            <Grid item xs={12} sm={3} className={classes.filterButtonsContainer}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={classes.filterButtons}
                onClick={() => {
                  onSetFilters(Object.keys(filters).map((key) => filters[key as keyof Filters]));
                }}
              >
                <Typography variant={"button"} className={classes.filterButtonText}>
                  Apply filters
                </Typography>
                <SearchIcon fontSize={"default"} className={classes.filterButtonText} />
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={classes.filterButtons}
                onClick={() => {
                  setFilters(stagedFilters);
                  onSetFilters(Object.keys(stagedFilters).map((key) => stagedFilters[key as keyof Filters]));
                }}
              >
                <Typography variant={"button"} className={classes.filterButtonText}>
                  Clear filters
                </Typography>
                <ClearIcon fontSize={"default"} className={classes.filterButtonText} />
              </Button>
            </Grid>
            <Tabs
              value={tab}
              variant="scrollable"
              scrollButtons="on"
              onChange={(e, value) => handleChangeTab(value as FilterSection)}
              className={classes.tabs}
            >
              {Object.values(FilterSection).map((section) => {
                return <Tab value={section} label={section} key={section} />;
              })}
            </Tabs>

            {Object.values(FilterSection).map((section) => {
              const sectionFiltersKeys = Object.keys(filters).filter((key) => {
                return filters[key as keyof Filters].filterSection === section;
              });
              return (
                <React.Fragment key={section}>
                  {section === tab && (
                    <Grid
                      item
                      container
                      xs={12}
                      spacing={3}
                      hidden={section !== tab}
                      className={classes.filterContainer}
                    >
                      {sectionFiltersKeys.map((key, index) => {
                        const property = key as keyof Filters;
                        return (
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={`${key}_${index}`}>
                            <FilterContainer
                              title={filters[property].title}
                              value={filters[property].value}
                              op={filters[property].op}
                              secondValue={filters[property].secondValue}
                              secondOp={filters[property].secondOp}
                              subTitles={filters[property].subtitles}
                              values={filters[property].values}
                              type={filters[property].filterType}
                              propertyName={property}
                              filterRows={filterRows}
                              valueType={filters[property].type}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
