import {
  ExpectedBrightness,
  ExpectedLocation,
  expectedBrightnessToFilterValue,
  expectedLocationToFilterValue,
} from "../models/log";
import { Filter, FilterType } from "../models/filter";
import { ImageExtensionFormat, StringFilterType } from "../models/image";
import { ModelType, getModelTypeName } from "../models/utils";

import { PredictionFormulaResult } from "../models/prediction";

const Version = " version";

export enum FilterSection {
  Request = "Request",
  Prediction = "Prediction",
  ModelResults = "Model results",
  ModelVersion = "Model version",
  Metadata = "Metadata",
  Sniffers = "Sniffers",
  UserAgentHints = "User agent hints",
}

export interface FilterUI extends Filter {
  title: string;
  subtitles?: string[];
  values?: (string | null)[] | (number | null)[] | (boolean | null)[];
  filterType: FilterType;
  filterSection: FilterSection;
}

export const initialFilters: Filters = {
  predictionResult: {
    title: "Prediction result",
    subtitles: Object.keys(PredictionFormulaResult),
    values: Object.values(PredictionFormulaResult),
    filterType: FilterType.Menu,
    value: undefined,
    column: "prediction_result",
    type: "string",
    filterSection: FilterSection.Prediction,
  },
  expectedResult: {
    title: "Expected result",
    subtitles: ["Real", "Display Attack", "Print Attack", "Mask Attack", "Unknown"],
    values: ["real", "display_attack", "print_attack", "mask_attack", StringFilterType.Null],
    filterType: FilterType.Menu,
    value: undefined,
    column: "expected_result",
    type: "string",
    filterSection: FilterSection.Prediction,
  },
  expectedLocation: {
    title: "Expected location",
    subtitles: Object.keys(ExpectedLocation),
    values: Object.values(ExpectedLocation).map((value) => expectedLocationToFilterValue(value)),
    filterType: FilterType.Menu,
    value: undefined,
    column: "expected_location",
    type: "string",
    filterSection: FilterSection.Prediction,
  },
  expectedBrightness: {
    title: "Expected brightness",
    subtitles: Object.keys(ExpectedBrightness),
    values: Object.values(ExpectedBrightness).map((value) => expectedBrightnessToFilterValue(value)),
    filterType: FilterType.Menu,
    value: undefined,
    column: "expected_brightness",
    type: "string",
    filterSection: FilterSection.Prediction,
  },
  feedback: {
    title: "Feedback",
    subtitles: ["Correct", "Incorrect", "Unknown"],
    values: [StringFilterType.Correct, "incorrect", StringFilterType.Null],
    filterType: FilterType.Menu,
    value: undefined,
    column: "feedback",
    type: "string",
    filterSection: FilterSection.Prediction,
  },
  source: {
    title: "Source",
    subtitles: ["Camera", "Upload", "Sniffer", "MyFace"],
    values: [1, 0, 2, 3],
    filterType: FilterType.Menu,
    value: undefined,
    column: "from_camera",
    type: "number",
    filterSection: FilterSection.Prediction,
  },
  scanType: {
    title: "Scan type",
    subtitles: ["Multi scan", "Single scan", "Button scan", "Upload", "Sniffer", "MyFace", "FCM scan"],
    values: ["Multi scan", "Single scan", "Button scan", "Upload", "External client", "Data collection", "FCM scan"],
    filterType: FilterType.Menu,
    value: undefined,
    column: "scan_type",
    type: "string",
    filterSection: FilterSection.Prediction,
  },
  sniffer: {
    title: "Endpoint",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "sniffer_endpoint",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.Sniffers,
  },
  snifferUrl: {
    title: "Endpoint base URL",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "sniffer_base_url",
    type: "string",
    filterSection: FilterSection.Sniffers,
  },
  snifferMode: {
    title: "Endpoint mode",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "sniffer_mode",
    type: "string",
    filterSection: FilterSection.Sniffers,
  },
  OS: {
    title: "Operative system",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "os",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.Metadata,
  },
  browser: {
    title: "Browser",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "browser",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.Metadata,
  },
  device: {
    title: "Device",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "device_type",
    type: "string",
    filterSection: FilterSection.Metadata,
  },
  clientHintsOS: {
    title: "Client hints OS",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "client_hints_os",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.UserAgentHints,
  },
  clientHintsOSVersions: {
    title: "Client hints OS Version",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "client_hints_os_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.UserAgentHints,
  },
  clientHintsBrowser: {
    title: "Client hints browser",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "client_hints_browser",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.UserAgentHints,
  },
  clientHintsIsMobile: {
    title: "Client Hints Is Mobile",
    subtitles: ["Yes", "No"],
    values: [true, false],
    filterType: FilterType.Menu,
    value: undefined,
    column: "client_hints_is_mobile",
    type: "boolean",
    filterSection: FilterSection.UserAgentHints,
  },
  clientHintsDeviceMemory: {
    title: "Client Hints Device Memory",
    filterType: FilterType.Menu,
    value: undefined,
    values: [0.25, 0.5, 1, 2, 4, 8],
    column: "client_hints_device_memory",
    type: "number",
    filterSection: FilterSection.UserAgentHints,
  },
  clientHintsSaveData: {
    title: "Client Hints Save Data",
    subtitles: ["Yes", "No"],
    values: [true, false],
    filterType: FilterType.Menu,
    value: undefined,
    column: "client_hints_save_data",
    type: "boolean",
    filterSection: FilterSection.UserAgentHints,
  },
  clientHintsDownlink: {
    title: "Client Hints Downlink",
    filterType: FilterType.Menu,
    value: undefined,
    column: "client_hints_downlink",
    type: "number",
    filterSection: FilterSection.UserAgentHints,
  },
  clientHintsECT: {
    title: "Client Hints ECT",
    filterType: FilterType.Menu,
    values: ["Slow-2g", "2g", "3g", "4g"],
    value: undefined,
    column: "client_hints_ect",
    type: "string",
    filterSection: FilterSection.UserAgentHints,
  },
  clientHintsRTT: {
    title: "Client Hints RTT",
    filterType: FilterType.Menu,
    value: undefined,
    column: "client_hints_rtt",
    type: "number",
    filterSection: FilterSection.UserAgentHints,
  },
  clientHintsArch: {
    title: "Client Hints Arch",
    filterType: FilterType.Menu,
    value: undefined,
    column: "client_hints_arch",
    type: "string",
    filterSection: FilterSection.UserAgentHints,
  },
  clientHintsBitness: {
    title: "Client Hints Bitness",
    filterType: FilterType.Menu,
    value: undefined,
    column: "client_hints_bitness",
    type: "number",
    filterSection: FilterSection.UserAgentHints,
  },
  clientHintsModel: {
    title: "Client Hints Model",
    filterType: FilterType.Menu,
    value: undefined,
    column: "client_hints_model",
    type: "string",
    filterSection: FilterSection.UserAgentHints,
  },
  tag: {
    title: "Tag",
    filterType: FilterType.Search,
    value: undefined,
    column: "tag",
    type: "string",
    filterSection: FilterSection.Metadata,
  },
  userEmail: {
    title: "User email",
    filterType: FilterType.Search,
    value: undefined,
    column: "u.email",
    type: "string",
    filterSection: FilterSection.Request,
  },
  organisationName: {
    title: "Organisation name",
    filterType: FilterType.Search,
    value: undefined,
    column: "org.name",
    type: "string",
    filterSection: FilterSection.Request,
  },
  organisationToken: {
    title: "Organisation token",
    filterType: FilterType.Search,
    value: undefined,
    column: "org.token",
    type: "string",
    filterSection: FilterSection.Request,
  },
  id: {
    title: "ID",
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "l.id",
    type: "number",
    filterSection: FilterSection.Request,
  },
  time: {
    title: "Time",
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "time",
    type: "number",
    filterSection: FilterSection.Request,
  },
  deepFas: {
    title: getModelTypeName(ModelType.DepthFAS),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "df.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  maskDetector: {
    title: getModelTypeName(ModelType.MaskDetector),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "md.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  patchFas: {
    title: getModelTypeName(ModelType.PatchFAS),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "pf.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  deepFasRgb: {
    title: getModelTypeName(ModelType.RGBFAS),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "rf.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  deepFasRgbV2: {
    title: getModelTypeName(ModelType.RGBFASV2),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "rf2.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  deepFasRgbV2Confidence: {
    title: `${getModelTypeName(ModelType.RGBFASV2)} confidence`,
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "rf2.confidence",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  effNet: {
    title: getModelTypeName(ModelType.EffNet),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "en.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  metaFAS: {
    title: getModelTypeName(ModelType.MetaFAS),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "mf.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  metaFASV2: {
    title: getModelTypeName(ModelType.MetaFASV2),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "mf2.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  ageEstimation: {
    title: getModelTypeName(ModelType.AgeEstimation),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "age",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  biologicalSex: {
    title: getModelTypeName(ModelType.BiologicalSex),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "bs.probs",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  quality: {
    title: getModelTypeName(ModelType.FIQA),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "fiqa.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  contextPatch: {
    title: getModelTypeName(ModelType.ContextFAS),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "cf.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  idRnD: {
    title: getModelTypeName(ModelType.IDRnD),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "idrnd.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  imageExtensionFormat: {
    title: "Image extension format",
    subtitles: [ImageExtensionFormat.PNG, ImageExtensionFormat.JPEG, "", "ai error"],
    values: [
      ImageExtensionFormat.PNG,
      ImageExtensionFormat.JPEG,
      ImageExtensionFormat.Unknown,
      StringFilterType.AIError,
    ],
    filterType: FilterType.Menu,
    value: undefined,
    column: "image_extension_format",
    type: "string",
    filterSection: FilterSection.Metadata,
  },
  imageJPEGCompression: {
    title: "Image JPEG compression",
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "image_jpeg_compression",
    type: "number",
    filterSection: FilterSection.Metadata,
  },
  ip: {
    title: "Client IP",
    filterType: FilterType.Search,
    value: undefined,
    column: "ip",
    type: "string",
    filterSection: FilterSection.Request,
  },
  storeImagesEndpoint: {
    title: "Store Images Endpoint",
    subtitles: ["Yes", "No"],
    values: [true, false],
    filterType: FilterType.Menu,
    value: undefined,
    column: "cfg.store_images_endpoint",
    type: "boolean",
    filterSection: FilterSection.Sniffers,
  },
  sessionId: {
    title: "Session ID",
    filterType: FilterType.Search,
    value: undefined,
    column: "session_id",
    type: "string",
    filterSection: FilterSection.Request,
  },
  isSessionResult: {
    title: "Is session result",
    subtitles: ["Yes", "No"],
    values: [true, false],
    filterType: FilterType.Menu,
    value: undefined,
    column: "ai_session_final_result",
    type: "boolean",
    filterSection: FilterSection.Request,
  },
  applicationId: {
    title: "Application ID",
    filterType: FilterType.Search,
    value: undefined,
    column: "application_id",
    type: "string",
    filterSection: FilterSection.Request,
  },
  requestId: {
    title: "Request ID",
    filterType: FilterType.Search,
    value: undefined,
    column: "request_id",
    type: "string",
    filterSection: FilterSection.Request,
  },
  errorCode: {
    title: "Error Code",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "error_code",
    type: "string",
    filterSection: FilterSection.Prediction,
    exactMatch: true,
  },
  httpStatus: {
    title: "Prediction Status",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "prediction_status_code",
    type: "number",
    filterSection: FilterSection.Prediction,
  },
  metaFASV2B: {
    title: getModelTypeName(ModelType.MetaFASV2B),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "mf2b.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  metaFASV3: {
    title: getModelTypeName(ModelType.MetaFASV3),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "mf3.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  metaFASV4: {
    title: getModelTypeName(ModelType.MetaFASV4),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "mf4.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  metaFASV5: {
    title: getModelTypeName(ModelType.MetaFASV5),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "mf5.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  metaFASV7: {
    title: getModelTypeName(ModelType.MetaFASV7),
    filterType: FilterType.Range,
    value: undefined,
    secondValue: undefined,
    column: "mf7.real_percentage",
    type: "number",
    filterSection: FilterSection.ModelResults,
  },
  deviceField: {
    title: "Metadata Device Field",
    subtitles: ["Mobile", "Laptop", "Unknown"],
    values: ["mobile", "laptop", "unknown"],
    filterType: FilterType.Menu,
    value: undefined,
    column: "metadata_device",
    type: "string",
    filterSection: FilterSection.Request,
  },
  secureVersion: {
    title: "SICAP client version",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "secure_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.Prediction,
  },
  deepFasVersion: {
    title: getModelTypeName(ModelType.DepthFAS) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "df.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  maskDetectorVersion: {
    title: getModelTypeName(ModelType.MaskDetector) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "md.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  patchFasVersion: {
    title: getModelTypeName(ModelType.PatchFAS) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "pf.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  deepFasRgbVersion: {
    title: getModelTypeName(ModelType.RGBFAS) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "rf.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  deepFasRgbV2Version: {
    title: getModelTypeName(ModelType.RGBFASV2) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "rf2.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  effNetVersion: {
    title: getModelTypeName(ModelType.EffNet) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "en.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  metaFASVersion: {
    title: getModelTypeName(ModelType.MetaFAS) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "mf.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  metaFASV2Version: {
    title: getModelTypeName(ModelType.MetaFASV2) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "mf2.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  metaFASV2BVersion: {
    title: getModelTypeName(ModelType.MetaFASV2B) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "mf2b.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  metaFASV3Version: {
    title: getModelTypeName(ModelType.MetaFASV3),
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "mf3.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  metaFASV4Version: {
    title: getModelTypeName(ModelType.MetaFASV4),
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "mf4.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  metaFASV5Version: {
    title: getModelTypeName(ModelType.MetaFASV5),
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "mf5.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  metaFASV7Version: {
    title: getModelTypeName(ModelType.MetaFASV7),
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "mf7.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  ageEstimationVersion: {
    title: getModelTypeName(ModelType.AgeEstimation) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "age.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  biologicalSexVersion: {
    title: getModelTypeName(ModelType.BiologicalSex) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "bs.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  qualityVersion: {
    title: getModelTypeName(ModelType.FIQA) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "fiqa.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  contextPatchVersion: {
    title: getModelTypeName(ModelType.ContextFAS) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "cf.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  idRnDVersion: {
    title: getModelTypeName(ModelType.IDRnD) + Version,
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "idrnd.model_version",
    type: "string",
    exactMatch: true,
    filterSection: FilterSection.ModelVersion,
  },
  isSecure: {
    title: "Is secure",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "is_secure",
    type: "boolean",
    filterSection: FilterSection.Request,
  },
  levelOfAssurance: {
    title: "Level of assurance",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "level_of_assurance",
    type: "string",
    filterSection: FilterSection.Request,
    exactMatch: true,
  },
  operator: {
    title: "Operator",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "operator",
    type: "string",
    filterSection: FilterSection.Request,
    exactMatch: true,
  },
  threshold: {
    title: "Threshold",
    subtitles: [],
    values: [],
    filterType: FilterType.Range,
    value: undefined,
    column: "l.threshold",
    type: "number",
    filterSection: FilterSection.Request,
    exactMatch: true,
  },
  imgValidationLevel: {
    title: "Image validation level",
    subtitles: [],
    values: [],
    filterType: FilterType.Menu,
    value: undefined,
    column: "img_validation_level",
    type: "string",
    filterSection: FilterSection.Request,
    exactMatch: true,
  },
};

export interface Filters {
  predictionResult: FilterUI;
  expectedResult: FilterUI;
  expectedLocation: FilterUI;
  expectedBrightness: FilterUI;
  feedback: FilterUI;
  source: FilterUI;
  scanType: FilterUI;
  sniffer: FilterUI;
  snifferUrl: FilterUI;
  snifferMode: FilterUI;
  OS: FilterUI;
  browser: FilterUI;
  device: FilterUI;
  clientHintsOS: FilterUI;
  clientHintsOSVersions: FilterUI;
  clientHintsBrowser: FilterUI;
  clientHintsIsMobile: FilterUI;
  clientHintsDeviceMemory: FilterUI;
  clientHintsSaveData: FilterUI;
  clientHintsDownlink: FilterUI;
  clientHintsECT: FilterUI;
  clientHintsRTT: FilterUI;
  clientHintsArch: FilterUI;
  clientHintsBitness: FilterUI;
  clientHintsModel: FilterUI;
  tag: FilterUI;
  userEmail: FilterUI;
  organisationName: FilterUI;
  organisationToken: FilterUI;
  time: FilterUI;
  id: FilterUI;
  deepFas: FilterUI;
  maskDetector: FilterUI;
  patchFas: FilterUI;
  deepFasRgb: FilterUI;
  deepFasRgbV2: FilterUI;
  deepFasRgbV2Confidence: FilterUI;
  effNet: FilterUI;
  metaFAS: FilterUI;
  metaFASV2: FilterUI;
  ageEstimation: FilterUI;
  biologicalSex: FilterUI;
  quality: FilterUI;
  contextPatch: FilterUI;
  idRnD: FilterUI;
  imageExtensionFormat: FilterUI;
  imageJPEGCompression: FilterUI;
  ip: FilterUI;
  storeImagesEndpoint: FilterUI;
  sessionId: FilterUI;
  isSessionResult: FilterUI;
  applicationId: FilterUI;
  requestId: FilterUI;
  errorCode: FilterUI;
  httpStatus: FilterUI;
  metaFASV2B: FilterUI;
  metaFASV3: FilterUI;
  metaFASV4: FilterUI;
  metaFASV5: FilterUI;
  metaFASV7: FilterUI;
  deviceField: FilterUI;
  secureVersion: FilterUI;
  deepFasVersion: FilterUI;
  maskDetectorVersion: FilterUI;
  patchFasVersion: FilterUI;
  deepFasRgbVersion: FilterUI;
  deepFasRgbV2Version: FilterUI;
  effNetVersion: FilterUI;
  metaFASVersion: FilterUI;
  metaFASV2Version: FilterUI;
  metaFASV2BVersion: FilterUI;
  metaFASV3Version: FilterUI;
  metaFASV4Version: FilterUI;
  metaFASV5Version: FilterUI;
  metaFASV7Version: FilterUI;
  ageEstimationVersion: FilterUI;
  biologicalSexVersion: FilterUI;
  qualityVersion: FilterUI;
  contextPatchVersion: FilterUI;
  idRnDVersion: FilterUI;
  isSecure: FilterUI;
  levelOfAssurance: FilterUI;
  operator: FilterUI;
  threshold: FilterUI;
  imgValidationLevel: FilterUI;
}
